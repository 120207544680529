import React, { useEffect, useRef, useState } from "react";
import logo from "./Header_Logo.png";
import header_img_dark from "./header.jpg";
import { SiteHelper } from "./helpers/SiteHelper";
import "./App.css";
import { SupportLinks } from "./SupportLinks";
import { StepSubmitted } from "./StepSubmitted";
import { ErrorMessage } from "./ErrorMessage";
import { StepPolicy } from "./StepPolicy";
import { StepUserData } from "./StepUserData";
import { ValidationResult, UserData, UserMailData } from "./Types";
import { StepEmail } from "./StepEmail";
import { StepCheck } from "./StepCheck";
import { Validation } from "./helpers/Validation";
import { PageProps } from "./interfaces/ComponentProps";
import { Trans, useTranslation } from "react-i18next";
import i18n from "./i18n";

export const OnBoard = ({country, lang} : PageProps) => {
  const { t } = useTranslation(['onBoard']);

  const [loading, setLoading] = useState(false);
  const initialbuttonNextText = t("button.nextText");
  const initialbuttonSubmitText = t("button.submitText");
  const initialbuttonWaitingText = t("button.waitingText");
  const [buttonNext, setbuttonNext] = useState(initialbuttonNextText);
  const [buttonSubmit, setbuttonSubmit] = useState(initialbuttonSubmitText);

  const [errorMessage, seterrorMessage] = useState("");
  const [currentStep, setcurrentStep] = useState("policy");
  const [policystep, setpolicystep] = useState(true);
  const [datastep, setdatastep] = useState(false);
  const [mailstep, setmailstep] = useState(false);
  const [checkstep, setcheckstep] = useState(false);
  const [submittedstep, setsubmittedstep] = useState(false);

  const [step, setStep] = useState("");

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    i18n.changeLanguage(lang);
  }, [lang])

  const humanKeyRef = useRef("");
  const policyCallback = (key: string) => {
    // console.log("PolicyCollack Called:", key);
    humanKeyRef.current = key;
  };

  const userDataRef = useRef<UserData>({ badgeNumber: "", ssn: "" });
  const userDataCallback = (userData: UserData) => {
    // console.log("userDataCallback Called:", userData);
    userDataRef.current = userData;
  };

  const userMailDataRef = useRef<UserMailData>({ email: "", emailCheck: "" });
  const userMailDataCallback = (userMailData: UserMailData) => {
    // console.log("userDataCallback Called:", userData);
    userMailDataRef.current = userMailData;
  };

  async function handleNext(name: any) {
    setcurrentStep(name);
    setbuttonNext(initialbuttonWaitingText);
    seterrorMessage("");

    setLoading(true);
    if (name == "policy") {
      var recaptchaValidationResult: ValidationResult =
        await Validation.ValidateRecaptcha(humanKeyRef.current);
      // console.log("checkRecaptcha : " + checkRecaptcha_message + " - " + checkRecaptcha);
      if (recaptchaValidationResult.isValid) {
        setStep("second");
        setpolicystep(false);
        setdatastep(true);
      } else {
        seterrorMessage(recaptchaValidationResult.message);
      }
    } else if (name == "data") {
      var userDataValidationResult: ValidationResult =
        await Validation.ValidateUserData(userDataRef.current, t, lang, country);
      if (userDataValidationResult.isValid) {
        setStep("third");
        setdatastep(false);
        setmailstep(true);
      } else {
        console.log(userDataValidationResult.message);
        seterrorMessage(userDataValidationResult.message);
      }
    } else if (name == "mail_upn") {
      var userMailDataValidationResult: ValidationResult =
        await Validation.ValidateMailData(userMailDataRef.current, t, lang);
      if (userMailDataValidationResult.isValid) {
        setStep("fourth");
        setmailstep(false);
        setcheckstep(true);
      } else {
        seterrorMessage(userMailDataValidationResult.message);
      }
    }

    setLoading(false);
    setbuttonNext(initialbuttonNextText);
  }

  async function gobackfix() {
    setStep("second");
    seterrorMessage("");
    setcheckstep(false);
    setmailstep(false);
    setdatastep(true);
  }

  async function submitRequest() {
    setbuttonSubmit(initialbuttonWaitingText);
    setLoading(true);
    seterrorMessage("");
    const userData = userDataRef.current;
    const userMailData = userMailDataRef.current;
    var status: boolean = await SiteHelper.CreateRequest(
      userData.badgeNumber,
      userData.ssn,
      userMailData.email,
      country,
      lang
    );

    // console.log(status);
    if (status) {
      setsubmittedstep(true);
      // setChecked(false);
      setcheckstep(false);
    } else {
      seterrorMessage(
        SiteHelper.ManageErrorMessage(t("error.requestCreation"))
      );
    }

    setLoading(false);

    setbuttonSubmit(initialbuttonSubmitText);
  }

  const [input, setInput] = useState(false);
  return (
    <div className="Container-Main">
      <a href={`${window.location.origin}`}>
        <img src={logo} className="App-logo" alt="logo" />
      </a>
      <img src={header_img_dark} className="App-logo" alt="logo" />
      <div className="App">
        <div className="App-header">
          <div className="App_body">
            <div>
              {!checkstep && (
                <h1 className={"title_center"}>
                  {submittedstep
                    ? <Trans t={t} i18nKey="pageOnboardText_SubmittedTitle" />
                    : <Trans t={t} i18nKey="pageOnboardText_Title" />}
                </h1>
              )}
            </div>

            <div className={"panel_onboard"}>
              {policystep && (
                <>
                  <StepPolicy
                    nextButtonText={buttonNext}
                    onNext={async () => await handleNext("policy")}
                    onValidate={policyCallback}
                    country={country}
                    lang={lang}
                  />
                </>
              )}

              {datastep && (
                <>
                  <StepUserData
                    defaultValue={userDataRef.current}
                    nextButtonText={buttonNext}
                    onNext={async (step) => await handleNext(step)}
                    onValidate={userDataCallback}
                    lang={lang}
                  />
                </>
              )}

              {mailstep && (
                <>
                  <StepEmail
                    defaultValue={userMailDataRef.current}
                    nextButtonText={buttonNext}
                    prevButtonText={t("button.back")}
                    onNext={async (step) => await handleNext(step)}
                    onPrev={async () => await gobackfix()}
                    onValidate={userMailDataCallback}
                    lang={lang}
                  />
                </>
              )}

              {checkstep && (
                <>
                  <StepCheck
                    userData={userDataRef.current}
                    userMailData={userMailDataRef.current}
                    submitButtonText={buttonSubmit}
                    prevButtonText={t("button.back")}
                    onSubmit={async () => await submitRequest()}
                    onPrev={async () => await gobackfix()}
                    lang={lang}
                  />
                </>
              )}

              {errorMessage != "" && (
                <ErrorMessage errorMessage={errorMessage} />
              )}

              {submittedstep && <StepSubmitted lang={lang} />}
            </div>

            <SupportLinks lang={lang} country={country}/>
          </div>
        </div>
      </div>
    </div>
  );
}
